<template>
  <div class="billing-history-summary">
    <main-card class="billing-history-summary__card">
      <transition name="fade" mode="out-in">
        <base-loader
          v-if="isLoading && !grouppedList.length"
          class="billing-history-summary__loader"
        />
        <div v-else class="billing-history-summary__content">
          <div
            v-for="group in grouppedList"
            :key="group.from.valueOf()"
            class="billing-history-summary__item"
          >
            <div class="billing-history-summary__item-head medium-title">
              <div class="billing-history-summary__item-title">
                {{ $d(group.from, 'monthYear') }}
              </div>
              <div
                v-if="isThisMonth(group.from)"
                class="billing-history-summary__item-balance l-hidden-ms-down"
              >
                {{ $t('balance') + ': ' + $n(balance, 'currency') }}
              </div>
            </div>
            <div class="billing-history-summary__item-content">
              <base-collapser
                v-for="(list, key) in group.list"
                :key="key + '-' + group.from.valueOf() + '-' + list.length"
                class="billing-history-summary__group"
                :class="`billing-history-summary__group--${key}`"
              >
                <template #summary>
                  {{
                    (list.length ? $t(`type.${key}.title`) + ': ' : '') +
                    $tc(`type.${key}.item`, list.length)
                  }}
                </template>
                <template v-if="group[key]" #summaryEnd>
                  {{ typeSymbol[key] + ' ' + $n(group[key], 'currency') }}
                </template>
                <div v-if="list.length" class="billing-history-summary__group-content">
                  <div
                    v-for="item in list"
                    :key="item.id"
                    class="billing-history-summary__group-item note-color"
                  >
                    <div class="billing-history-summary__group-item-name">
                      {{ $d(item.date, 'short') + ' ' + item.name }}
                    </div>
                    <div class="billing-history-summary__group-item-sum">
                      {{ typeSymbol[key] + ' ' + $n(item.sum, 'currency') }}
                    </div>
                  </div>
                </div>
              </base-collapser>
            </div>
          </div>
        </div>
      </transition>
    </main-card>
  </div>
</template>

<script>
import BaseCollapser from '@/components/BaseCollapser/BaseCollapser.vue';
import { isDate, isThisMonth } from 'date-fns';
export default {
  name: 'BillingHistorySummary',
  components: { BaseCollapser },
  props: {
    dateFilter: {
      type: Object,
      default: null,
      validator: obj => {
        return !obj || (obj.start && isDate(obj.start) && obj.end && isDate(obj.end));
      },
    },
  },
  data() {
    return {
      typeSymbol: {
        payment: '+',
        expense: '-',
      },
    };
  },
  computed: {
    grouppedList() {
      return this.$store.getters['moduleBilling/moduleHistory/getGrouppedList'];
    },
    isLoading() {
      return this.$store.state.moduleBilling.moduleHistory.isLoading;
    },
    balance() {
      return this.$store.getters['moduleProfile/balance'];
    },
  },
  methods: {
    isThisMonth(date) {
      return isThisMonth(date);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "type": {
      "payment": {
        "title": "Пополнение баланса",
        "item": "Пополнений не было | {n} платеж | {n} платежа | {n} платежей"
      },
      "expense": {
        "title": "Расходы",
        "item": "Услуги не оплачивались | оплачено {n} услуга | оплачено {n} услуги | оплачено {n} услуг"
      }
    },
    "balance": "Баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.billing-history-summary {
  &__card {
    +breakpoint(md-and-up) {
      max-width: 40rem;
    }
  }
  &__item {
    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 2.5rem;
      }
    }

    &-head {
      flexy(space-between, baseline);
    }

    &-title:first-letter {
      text-transform: uppercase;
    }

    &-balance {
      flex: 0 0 auto;
    }

    &-content {
      margin-top: 1rem;
    }
  }

  &__group {
    &-item {
      flexy(space-between, baseline);

      +breakpoint(sm-and-up) {
        padding-right: 3rem;
      }

      & + & {
        margin-top: 1rem;
      }

      &-sum {
        flex: 0 0 auto;
        margin-left: 1.25rem;
      }
    }
  }
}
</style>
